import request from './request';

export default {
  buildURL(url, opts, search) {
    let args = [];

    if (opts.page) {
      args.push(`page=${opts.page}&size=${opts.itemsPerPage}`);
    }

    if (search?.length > 0) {
      args.push(`search=${search}`);
    }

    if (opts.filters) {
      opts.filters.forEach((f) => {
        let field = f.field;
        if (field == 'order') {
          field = 'order__order_no';
        }
        args.push(`${field}${f.operation}${f.value}`);
      });
    }

    let sort = [];
    for (let i = 0; i < opts.sortBy?.length; i++) {
      let sign = '';
      if (i < opts.sortDesc?.length && opts.sortDesc[i]) {
        sign += '-';
      }
      let field = opts.sortBy[i].replace('.', '__');

      if (field == 'operator') {
        field = 'operator__username';
      }
      if (field == 'order') {
        field = 'order__order_no';
      }

      sort.push(sign + field);
    }
    if (sort.length > 0) {
      args.push(`ordering=${sort.join(',')}`);
    }

    if (args.length == 0) {
      return url;
    }

    url = `${url}?${args.join('&')}`;

    return url;
  },

  async post(item) {
    console.log('post parcels item: ', item);
    const result = await request.request(`/api/parcels/`, 'post', item, {});

    console.log('result: ', result);
    return result;
  },

  async getItems(opts, search) {
    let url = this.buildURL(`/api/parcels/`, opts, search);
    console.log('url:', url);
    let all = !opts.page;

    if (all) {
      let items = await request.allPages(url, (url) => {
        return request.request(url, 'get', {}, {});
      });
      return [items, items.length];
    }

    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async patch(item) {
    console.log('patch parcels item: ', item);
    let id = item.id;
    const result = await request.request(
      `/api/parcels/${id}/`,
      'patch',
      item,
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async delete(item) {
    console.log('delete package item: ', item);
    let id = item.id;
    const result = await request.request(
      `/api/parcels/${id}/`,
      'delete',
      {},
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async excel(opts, search) {
    console.log('Generate parcels.xlsx');
    let url = `/api/parcels/excel/`;
    url = this.buildURL(url, opts, search);

    console.log('url:', url);

    await request.download(url, 'parcels.xlsx');
  }
};
